<template>
  <v-card class="mx-auto" color="primary" dark max-width="400">
    <v-card-title class="text-xs-center">
      <v-icon large left>attach_money</v-icon>
      <span class="title font-weight-light">Total faturado</span>
    </v-card-title>
    <v-card-text class="headline font-weight-bold text-xs-center">R$ {{total | currency}}</v-card-text>
  </v-card>
</template>

<script>
const totalFaturadoModule = () => import('./../../../services/dashboard')

export default {
  name: 'CardTotalFaturado',
  data () {
    return {
      total: 0
    }
  },
  mounted () {
    this.getTotalFaturado()
  },
  methods: {
    async getTotalFaturado () {
      try {
        let totaFaturadoService = await totalFaturadoModule()
        let resp = await totaFaturadoService.totalFaturado(this.$axios)
        if (resp.status === 200 && resp.data) {
          this.total = resp.data.valor_total
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>